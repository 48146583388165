import React, { useState, useEffect, useRef } from 'react';

import {
    getToken,
    getTransactions,
    getPayment,
    getPaymentMethod,
    getShopData,
} from 'api/requests';

import { compareSignaturePayment } from 'utilities/signatureCheckoutPayment/CompareSignaturePayment';

import LoaderView from 'components/loading/LoaderView';
import PaymentView from './PaymentView';

const PaymentStatus = () => {
    const [payment, setPayment] = useState('');
    const [methodsFlag, setMethodsFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState();
    const [redirect, setRedirect] = useState(false);

    const [paymentData, setPaymentData] = useState({
        completedAt: '',
        amount: '',
        canonicalId: '',
        referenceId: '',
        createdAt: '',
        selectedPaymentMethod: '',
        providerCanonicalId: null,
        description: '',
    });

    const paymentIntervalRef = useRef();

    useEffect(() => {
        const paymentUid = window.location.pathname
            .split('/payments/')
            .pop()
            .split('/status')
            .shift();
        sessionStorage.setItem('fullUrl', window.location.href);

        if (!sessionStorage.getItem('signature')) {
            sessionStorage.setItem(
                'signature',
                window.location.search.split('=').pop(),
            );
        }

        setPayment(paymentUid);
        sessionStorage.setItem('payment', paymentUid);
        compareSignaturePayment(payment, sessionStorage.getItem('transaction'));
        setMethodsFlag(true);
        sessionStorage.setItem('payment', payment);
        setRedirect(
            window.location.search
                .split('?source=')
                .pop()
                .split('&')
                .shift() === 'redirect',
        );

        if (
            window.location.search
                .split('?source=')
                .pop()
                .split('&')
                .shift() === 'email'
        ) {
            sessionStorage.setItem('emailSource', true);
        } else if (
            window.location.search
                .split('?source=')
                .pop()
                .split('&')
                .shift() === 'redirect'
        ) {
            sessionStorage.setItem('emailSource', false);
        }
    }, [payment]);

    const handleCommunication = async () => {
        await getToken(sessionStorage.getItem('signature')).then(response => {
            sessionStorage.setItem('token', response.data.token);
        });

        await getPayment(sessionStorage.getItem('payment')).then(response => {
            setIsLoading(true);
            sessionStorage.setItem(
                'amount',
                parseInt(response.data.amount, 10),
            );
            sessionStorage.setItem('transaction', response.data.transactionId);
            setPaymentData({
                amount: response.data.amount,
                completedAt: response.data.completedAt,
                createdAt: response.data.createdAt,
                canonicalId: response.data.canonicalId,
                providerCanonicalId: response.data.providerCanonicalId,
            });
            setPaymentStatus(response.data.status);
        });
        await getTransactions(sessionStorage.getItem('transaction')).then(
            response => {
                sessionStorage.setItem(
                    'selectedPaymentMethodId',
                    response.data.selectedPaymentMethodId,
                );
                sessionStorage.setItem(
                    'providerName',
                    response.data.providerName,
                );
                sessionStorage.setItem('shopId', response.data.shopId);
                setPaymentData(prevState => ({
                    ...prevState,
                    referenceId: response.data.order.reference,
                    description: response.data.description,
                }));
                sessionStorage.setItem('returnUrl', response.data.returnUrl);
                sessionStorage.setItem('customerId', response.data.customerId);
                sessionStorage.setItem(
                    'completedAt',
                    response.data.completedAt,
                );

                const query = window.location.search.substring(1);

                if (query.length) {
                    if (
                        window.history !== undefined &&
                        window.history.pushState !== undefined
                    ) {
                        window.history.pushState(
                            {},
                            document.title,
                            window.location.pathname,
                        );
                    }
                }
            },
        );
        await getPaymentMethod(
            sessionStorage.getItem('selectedPaymentMethodId'),
        ).then(response => {
            setPaymentData(prevState => ({
                ...prevState,
                selectedPaymentMethod: response.data.name,
            }));
        });
        await getShopData(sessionStorage.getItem('shopId'))
            .then(response => {
                sessionStorage.setItem('shopName', response.data.name);
            })
            .then(() => {
                setIsLoading(false);
            });
    };

    const clearPaymentTimeoutAndSetSessionStorageKey = () => {
        clearTimeout(paymentIntervalRef.current);
        sessionStorage.setItem('sseResponse', 'true');
    };

    const handleRedirect = status => {
        if (!status) {
            throw new Error('Brak statusu transakcji');
        }

        if (status === 4) {
            clearPaymentTimeoutAndSetSessionStorageKey();
        }
    };

    const getPaymentStatus = () => {
        clearTimeout(paymentIntervalRef.current);

        paymentIntervalRef.current = setTimeout(() => {
            getPayment(sessionStorage.getItem('payment')).then(({ data }) => {
                const { status } = data;

                if (status === 4) {
                    return clearPaymentTimeoutAndSetSessionStorageKey();
                }

                getPaymentStatus();
            });
        }, 1000 * 3);
    };

    useEffect(() => {
        if (
            sessionStorage.getItem('token') &&
            sessionStorage.getItem('payment')
        ) {
            const payments = getPayment(sessionStorage.getItem('payment'));

            payments
                .then(response => {
                    handleRedirect(response.data.status);

                    return response;
                })
                .then(() => {
                    getPaymentStatus();
                });
        }
    }, [sessionStorage.getItem('token')]);

    useEffect(() => {
        handleCommunication();
    }, [methodsFlag === true]);

    return isLoading ? (
        <LoaderView />
    ) : (
        <PaymentView
            redirect={redirect}
            paymentData={paymentData}
            paymentStatus={paymentStatus}
        />
    );
};

export default PaymentStatus;
