import React from 'react';
import moment from 'moment-timezone';

import './view.scss';

const PaymentStatusDetails = ({ paymentData }) => {
    const handleAmountToDisplay = amount =>
        (amount / 100).toFixed(2).replace('.', ',');

    return (
        <table className="details">
            <tbody>
                <tr className="details__detail">
                    <td className="detail__name">Kwota transakcji:</td>
                    <td className="detail__value--cost">
                        <strong>
                            {handleAmountToDisplay(paymentData.amount)}
                            zł
                        </strong>
                    </td>
                </tr>
                <tr className="details__detail">
                    <td className="detail__name">Data zlecenia:</td>
                    <td className="detail__value">
                        <strong>
                            {paymentData.completedAt
                                ? moment
                                      .tz(
                                          paymentData.completedAt,
                                          'Europe/Warsaw',
                                      )
                                      .format('DD.MM.YYYY HH:mm:ss')
                                : moment
                                      .tz(
                                          paymentData.createdAt,
                                          'Europe/Warsaw',
                                      )
                                      .format('DD.MM.YYYY HH:mm:ss')}
                        </strong>
                    </td>
                </tr>
                <tr className="details__detail">
                    <td className="detail__name">Odbiorca:</td>
                    <td className="detail__value">
                        <strong>PayPo</strong>
                    </td>
                </tr>
                {paymentData.providerCanonicalId && (
                    <tr className="details__detail">
                        <td className="detail__name">Nr transakcji:</td>
                        <td className="detail__value">
                            <strong>{paymentData.providerCanonicalId}</strong>
                        </td>
                    </tr>
                )}
                <tr className="details__detail">
                    <td className="detail__name">Opis:</td>
                    <td className="detail__value">
                        <strong>{paymentData.description}</strong>
                    </td>
                </tr>
                <tr className="details__detail">
                    <td className="detail__name">Identyfikator PayPo:</td>
                    <td className="detail__value">
                        <strong>{paymentData.canonicalId}</strong>
                    </td>
                </tr>
                <tr className="details__detail">
                    <td className="detail__name">Forma płatności:</td>
                    <td className="detail__value">
                        <strong>{paymentData.selectedPaymentMethod}</strong>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default PaymentStatusDetails;
